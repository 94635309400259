import { Content } from "@/models/content";
import { ContentManager } from "@/services/contentManager";

export const day3: Content[] = [
    {
        id: "05870aef-9af9-4984-a76f-2843f9b228be",
        text: [
            "Hi <nick name>! Welcome back to wMammogram project!"
        ],
        textStyle: [
            ["text-h5", "text-center"]
        ],
        pinkie: {
            src: require("@/assets/pinkie/hello.png"),
            animation: "pinkie-hello",
            right: true
        }
    },
    {
        id: "941c9a97-577c-481b-acfa-69174e99d18e",
        text: [
            "Today, I’d like to talk about mammogram guidelines and concerns you might have about getting a regular mammogram.",
            "Are you ready?"
        ],
        nextStepText: "I'm Ready!"
    },
    {
        id: "296edbad-ad58-4751-8e43-f07bc9922449",
        text: [
            "Here’s the first question!"
        ],
        question: {
            question: "Is the following statement true or false?\n\"Women aged 40 or older need to receive a mammogram.\"",
            answers: [
                "True",
                "False"
            ],
            correct: 0,
            time: 0,
            text: [
                [
                    "You’re right!",
                    "American Cancer Society recommends that women aged 40 or older should continue getting a regular mammogram for as long as they are in good health.",
                    "You should discuss with your health providers when you stop receiving mammograms."
                ],
                [
                    "Actually, the statement is correct.",
                    "American Cancer Society recommends that women aged 40 or older should continue getting a regular mammogram for as long as they are in good health.",
                    "You should discuss with your health providers when you stop receiving mammograms."
                ]
            ],
            pinkie: {
                correct: require("@/assets/pinkie/thumbs-up.png")
            }
        }
    },
    {
        id: "5891b300-97a4-4716-adc4-a0e8c9cc753e",
        text: [
            "So far, we’ve frequently used a phrase, \"getting a regular mammogram.\""
        ],
        question: {
            question: "How often do you think you need to get a mammogram?",
            answers: [
                "Every 1 year",
                "Every 3 years"
            ],
            correct: 0,
            time: 0,
            text: [
                [
                    "Yes!!!",
                    "American Cancer Society recommends getting a mammogram yearly."
                ],
                [
                    "Nice try!",
                    "But American Cancer Society recommends getting a mammogram yearly."
                ]
            ],
            common: [
                "Frankly, there are other institutions recommending a mammogram every 2 years.",
                "A guideline that you need to follow should be decided after considering potential risks that you have for breast cancer.",
                "Please discuss this with your health providers."
            ]
        }
    },
    {
        id: "1ca3ab86-974a-416e-b44e-d138fce8c55e",
        text: [
            "Now we will talk about pain that may be induced during a mammogram."
        ],
        question: {
            question: "Do you think that mammogram procedures can cause a severe pain during the exam?",
            answers: [
                "Yes",
                "No"
            ],
            correct: 0,
            time: 0,
            text: [
                [
                    "Yes, it is completely reasonable that you’re worried about pain."
                ],
                [
                    "You’re right that mammograms generally don’t cause severe pain."
                ]
            ],
            common: [
                "However, mammograms can feel different for each person."
            ]
        }
    },
    {
        id: "c1a1db53-5ff7-47e3-a04d-16f300d4c7bb",
        text: [
            "Let’s hear how much pain Ms. Jamie Stead felt during a mammogram."
        ],
        video: {
            srcset: {
                hd: require("@/assets/videos/Survivor Jamie S.mp4"),
            },
            drive: {
                sd: "19qh7usiT91fRmBBPe9LMoS4qrcTxxWZl",
                hd: "1_YrmTgn2XPPv_s0LSWs5It4xeHhz79vQ",
                uhd: "1KzKsu52bEbedvEP2ttPdsDOgZcB8fFcn"
            }
        }
    },
    {
        id: "8b17818d-2855-4b1c-adfd-94f210e550aa",
        text: [
            "As Ms. Stead said, you may feel slight pain or discomfort during the procedure since it will press your breast to get an x-ray image.",
            "However, you will get the benefit of checking potential health risks.",
            "Please note that your mammogram technician will personalize your mammogram to minimize any pain and improve your overall experience!",
            "If you think the pain is too severe, you can just let your technician know about it!"
        ]
    },
    {
        id: "93daa95f-c478-4146-97be-6d912b68d620",
        text: [
            "Ah! Here is one more tip",
            "Your breasts may be more sensitive just before your menstrual cycle so schedule your mammogram appointment for after that time of the month!"
        ],
        pinkie: {
            src: require("@/assets/pinkie/idea.png"),
            left: true
        }
    },
    {
        id: "5d028f13-51c8-4bb8-98e9-42da6bff9611",
        text: [
            "Except for the potential pain, there are several people saying \"getting a mammogram is not good for your health because of radiation.\""
        ],
        question: {
            question: "What do you think about it?",
            answers: [
                "I agree",
                "I do not agree"
            ],
            correct: 1,
            time: 0,
            text: [
                [
                    "It is true that mammograms require radiation, but in very small doses."
                ],
                [
                    "You're right!"
                ]
            ],
            common: [
                "The risk of harm from the radiation exposure is extremely low since mammograms require very small doses of radiation.",
                "The benefits of mammogram outweigh the potential harm unless you have specific health issues such as being pregnant."
            ]
        }
    },
    {
        id: "1b30c8fb-d115-4f9d-876c-d27691142dd4",
        text: [
            "Professor LaPlante will talk about the risk of harm from the radiation exposure."
        ],
        video: {
            srcset: {
                hd: require("@/assets/videos/Radiation Info Kathy Laplante.mp4"),
            },
            drive: {
                sd: "1CvLS66c3Ij2ZQWh8-TICXSIiCFP4vbvH",
                hd: "1oVRdInaNXCP1L7NTiQTzOf802sscCBIt",
                uhd: "1CREKgrCNV0YmET77H1JWVOqAx_vVVEce"
            }
        }
    },
    {
        id: "aae8eda6-7632-4f02-9db0-80b28e322aee",
        text: [
            "Today, we talked about about mammograms. Please remember these:",
            "1) Women aged 40 or older should get a mammogram annually",
            "2) Worries about pain and radiation from a mammogram may be unnecessary!",
            "If you want our health navigator to help you make an appointment for a mammogram, please call at tel:605-202-1418 605-202-1418",
            "We’d like to help you!"
        ]
    },
    {
        id: "ee4bb1df-a3b9-4849-85ad-96629894f8b1",
        text: [
            "You will receive a notification later to inform you how many pink ribbons you have received today.",
            "We will get back to you with more fun tomorrow! Stay tuned!"
        ],
        pinkie: {
            src: require("@/assets/pinkie/wink.png"),
            right: true
        }
    }
];

export const manager = new ContentManager(day3);

export default day3;